// app.js
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import registerTailwindComponents from './registerTailwindComponents';
import FavouriteItem from './../../tools/dashboard/resources/js/Components/FavouriteItem.vue';

const sidebarApp = createApp();
sidebarApp.use(registerTailwindComponents);

sidebarApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
Sentry.attachErrorHandler(sidebarApp);

sidebarApp.component('favourite-item', FavouriteItem);

sidebarApp.mount('#sidebar');
